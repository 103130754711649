import React from 'react'
import Layout from '../components/layout'
import { useTranslation } from 'react-i18next'
import MainTitle from '../components/uielements/MainTitle'
import Spacer from '../components/uielements/Spacer'
import FlexWrapper from '../components/uielements/flexWrapper'
import Flex from '../components/uielements/Flex'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'

const MethodBox = styled.div`
    display: block;
    box-shadow: 0 0 14px #ccc;
    margin: 30px 20px;
    text-align: center;
    color: #fff;
    font-size: 1.2em;
    border-radius: 6px;

    a {
        color: ${({ theme }) => theme.blue};
        border: 4px solid ${({ theme }) => theme.blue};
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-decoration: none;
        border-radius: 6px;
        height: 200px;

        &:hover {
            background: ${({ theme }) => theme.blue};
            color: #fff;
        }
    }
`

const MethodsPage = ({ data: { methods } }) => {
    const { t, i18n } = useTranslation()
    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('methods')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('methods')} />
                <MainTitle>{t('methods')}</MainTitle>
                <Spacer top="3" />
                <FlexWrapper xl={{ margin: '0 -10px 0 -10px' }}>
                    {methods.nodes.map(
                        method =>
                            i18n.language === method.node_locale && (
                                <Flex
                                    flexBasis="33%"
                                    key={method.id}
                                    sm={{ flexBasis: '100%' }}
                                    md={{ flexBasis: '50%' }}
                                    lg={{ flexBasis: '50%' }}
                                >
                                    <MethodBox>
                                        <Link
                                            title={method.title}
                                            to={`/method/${method.slug}`}
                                            key={method.id}
                                        >
                                            {method.title}
                                        </Link>
                                    </MethodBox>
                                </Flex>
                            )
                    )}
                </FlexWrapper>
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query methodsQuery {
        methods: allContentfulMethods {
            nodes {
                id
                slug
                title
                node_locale
            }
        }
    }
`

export default MethodsPage
